import React, { useEffect, useState } from "react";
//import Footer from "./Footer";
import Header from "./Header";
//import Header2 from "./Header2";
//import Sidebar from "./Sidebar";


const Layout = props => {
    const [agente, setAgente] = useState(null);
    
    if (localStorage.getItem("agente") === null) window.location.href = "/login";
    useEffect(()=>{
      setAgente(JSON.parse(localStorage.getItem("agente")));
    },[]);
    return (
      <React.Fragment>
        {
            agente ? <Header data={agente}/> : null
        }
        <main className="container">{props.children}</main>
      </React.Fragment>
    );
};


export default Layout