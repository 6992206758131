import { useState, useEffect } from 'react';
import { BiMap } from 'react-icons/bi';
import { getPreviewPropertiesCard } from '../../data/cards';

const CardImmobili = (props) => {
    const [img, setImg] = useState("")
    const immobile = props.immobile
    useEffect(() => {
        getPreviewPropertiesCard(immobile.id).then((blob) => blob ? setImg(URL.createObjectURL(blob)) : null)
    },[])
    return(
        <div className="card">
            { img ? <img src={img} className="card-img-top" alt={"preview"} /> : null}
            <div className="card-body">
                <h5 className="card-title">{immobile.tipo.nome}</h5>
                <p className="card-text fs-6"><BiMap className='text-success' />{immobile.comune !== null ? immobile.comune.nome : null} - {immobile.zona !== null ? immobile.zona.nome : null}</p>
                { immobile.prezzo ? <h6 className="text-primary">€ {immobile.prezzo}</h6> : null}
            </div>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">Locali: {immobile.locali} Superficie: {immobile.superficie} Bagni: {immobile.bagni} Letti: {immobile.letti} Piano: {immobile.piano}</li>
                <li className="list-group-item">Codice: {immobile.codice}</li>
            </ul>
            <div className="card-footer">
                <a href={`/immobili/${immobile.id}`} className="btn btn-primary w-100">Vai all'immobile</a>
            </div>
        </div>
    )
}

export default CardImmobili