import React, { useEffect, useState, useCallback} from "react";
import { useParams } from "react-router-dom";
import UserProfileCard from "../components/cards/UserProfileCard"
import Richiesta from "../components/richiesta";
import { updateUtente, updateRichiesta, getSearch, getData } from "../data/utenti"
import CardImmobili from "../components/cards/CardImmobili";

const Utente = () => {
    const { id } = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    useEffect(()=> {
      getData(id).then(result => setData(result.data)).finally(()=> setLoading(false));
    },[])

    if (loading) return "Loading...";
    //console.log(data)
    const edit = (e) => {
        const element = e.target;
        data.utente[element.name] = element.value;
    }

    const submit = async (e) => {
        e.preventDefault();
        data.utente.agente =  JSON.parse(localStorage.getItem("agente")).agente.idAgente;
        const update = await updateUtente(id, data.utente);
        //console.log(update);
        if (!update.ok) {
            const element = document.getElementsByName(update.error.field)[0]
            console.log(element)
            element.className +=" is-invalid"
            const root = element.parentElement
            const div = document.createElement("div");
            div.className = "invalid-feedback";
            div.innerHTML  = update.error.message;
            root.appendChild(div);
        }
    }

    const editRicerca = async (e) => {
        data.richiesta.ricerca = e.target.checked
        await updateRichiesta(id, data.richiesta);
        forceUpdate();
    }

    const Ricerca = () => {
        const [ricerche, setRicerche] = useState([]);
        useEffect(() => {
            getSearch(id, data.richiesta).then(result => setRicerche(result.proposte))
        },[])
        return(
            <div className="row row-cols-1 row-cols-lg-3 g-2 g-lg-3">
                {
                    ricerche && ricerche.map((r, i) => {
                        return(
                            <div className="col" key={i}>
                                <CardImmobili immobile={r} />
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    return(
        <React.Fragment>
            <div className="row mt-5">
                <div className="col-sm-12 col-md-6 col-lg-4">
                    { data.utente ? <UserProfileCard user={data.utente}/> : null}
                </div>
                <div className="col-sm-12 col-md-6 col-lg-8 mt-3">
                    <form className="border p-4" onSubmit={submit}>
                        {
                            data.utente ? 
                                <div>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" name="nome" defaultValue={data.utente.nome} placeholder="Nome" aria-label="Nome" aria-describedby="basic-addon1" onChange={edit}/>
                                        <input type="text" className="form-control" name="ruolo" defaultValue={data.utente.ruolo} placeholder="Ruolo" aria-label="Ruolo" aria-describedby="basic-addon1" onChange={edit} />
                                    </div>
                                    
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" name="azienda" defaultValue={data.utente.azienda} placeholder="Azienda" aria-label="Azienda" aria-describedby="basic-addon1" onChange={edit}/>
                                        <input type="text" className="form-control" name="lavoro" defaultValue={data.utente.lavoro} placeholder="Lavoro" aria-label="Lavoro" aria-describedby="basic-addon1" onChange={edit}/>
                                    </div>
                                
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" name="telefono" defaultValue={data.utente.telefono} placeholder="Telefono" aria-label="Telefono" aria-describedby="basic-addon1" onChange={edit}/>
                                        <input type="text" className="form-control" name="telefono2" defaultValue={data.utente.telefono2} placeholder="Telefono 2" aria-label="Telefono 2" aria-describedby="basic-addon1" onChange={edit}/>
                                    </div>
                                
                                    <div className="input-group mb-3">
                                        <input type="email" className="form-control" name="email" defaultValue={data.utente.email} placeholder="email" aria-label="email" aria-describedby="basic-addon1" onChange={edit}/>
                                        <input type="email" className="form-control" name="email2" defaultValue={data.utente.email2} placeholder="email 2" aria-label="email2" aria-describedby="basic-addon1" onChange={edit}/>
                                    </div>

                                    <div className="mb-3">
                                        <button className="btn btn-secondary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                            Note
                                        </button>
                                        <div className="collapse mt-3" id="collapseExample">
                                            
                                            <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" name="note" defaultValue={data.utente.note} onChange={edit}></textarea>
                                            
                                        </div>
                                    </div>

                                    <div className="mb-2">
                                        <button type="submit" className="btn btn-primary w-100">Aggiorna</button>
                                    </div>
                                </div>
                            : null 
                        }
                    </form>
                </div>
            </div>

            <div className="p-4 bg-light rounded-3 mt-3">
                <h4 className="fw-bold">Richieste</h4>
                <Richiesta utente={id}/>
            </div>

            <div className="p-4 bg-light rounded-3 mt-3">
                <div className="row">
                    <div className="col-8">
                        <h4>Risultato richieste</h4>
                    </div>
                    <div className="col-4">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={data.richiesta ? data.richiesta.ricerca : false} disabled={data.richiesta ? false : true} onChange={editRicerca} />
                            <label className="form-check-label">Ricerca attiva</label>
                        </div>
                    </div>
                </div>
                {
                    data.richiesta && data.richiesta.ricerca == 1 ? <Ricerca/> : null
                }
            </div>

            <div className="p-4 bg-light rounded-3 mt-3">
                <h4>Proprieta'</h4>
                <div className="row row-cols-1 row-cols-lg-3 g-2 g-lg-3">
                    {
                        data.proprieta && data.proprieta.map((p,i)=> {
                            return(
                                <div className="col" key={i}>
                                    <CardImmobili immobile={p}/>
                                </div>
                            )
                        })
                    }
                    <div className="col">
                        <button className="btn btn-primary" onClick={() =>  window.location.href = `/immobili/add/${id}`}>Nuovo immobile</button>
                    </div>
                </div>
                
            </div>

            <div className="p-4 bg-light rounded-3 mt-3">
                <h4>Diario</h4>
                {
                    data.diario && data.diario.map((d,i) => {
                        return(
                            <div className="alert alert-primary" role="alert" key={i}>
                                <p className="text-break" dangerouslySetInnerHTML={{ __html: d.descrizione }}></p>- {d.agente} - {new Date(d.inserimento).toLocaleString()}
                            </div>
                        )
                    })
                }
            </div>
        </React.Fragment>
    )
}

export default Utente