const UserProfileCard = (props) => {
    const utente = props.user
    return(
        <div className="card text-center">
            
            <div className="card-body">
                <h5 className="card-title">{utente.nome}</h5>
                { utente.ruolo ? <p>({utente.ruolo})</p> : null}
                { utente.azienda || utente.lavoro ? <p>{utente.azienda} {utente.lavoro}</p> : null}
                { utente.email ? <p className="card-text">{utente.email} {utente.email2}</p> : null}
                { utente.telefono ? <p className="card-text">{utente.telefono} {utente.telefono2}</p> : null}
                
            </div>
            <div className="card-footer">
                <a className="btn" href={`tel:${utente.telefono}`}>chiama</a>
                <a className="btn" href={`https://wa.me/${utente.telefono}`} target="_blank" rel="noreferrer">whatsapp</a>
                <a className="btn" href={`mailto:${utente.email}`}>email</a>
            </div>
        </div>
    )
}

export default UserProfileCard