import { useState } from 'react';
import { BiMap } from 'react-icons/bi';

const CardImmobile = (props) => {
    const [img, setImg] = useState("")
    const immobile = props.immobile
    if(immobile)
    return(
        <div className="card">
            { img ? <img src={img} className="card-img-top" alt={"preview"} /> : null}
            <div className="card-body">
                <h5 className="card-title">{immobile.tipo ? immobile.tipo.nome : null}</h5>
                <p className="card-text fs-6"><BiMap className='text-success' />{immobile.comune ? immobile.comune.nome : null} - {immobile.zona ? immobile.zona.nome : null}</p>
                { immobile.prezzo ? <h6 className="text-primary">€ {immobile.prezzo}</h6> : null}
            </div>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">Locali: {immobile.locali} Superficie: {immobile.superficie} Bagni: {immobile.bagni} Letti: {immobile.letti} Piano: {immobile.piano}</li>
                <li className="list-group-item">Codice: {immobile.codice}</li>
            </ul>
            <div className="card-footer">
                <a href={`/immobili/${immobile.idImmobile}`} className="btn btn-primary w-100">Vai all'immobile</a>
            </div>
        </div>
    )
}

export default CardImmobile