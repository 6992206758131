import { proxy } from "../config";

export async function getData(id) {
  console.log(id)
  const response = await fetch(`${proxy}/api/utente/${id}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: ""
  });
  return response.json();
}

export async function addNewUser(obj) {
    const response = await fetch(`${proxy}/api/utente/add`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
    });
    return response.json();
}

export async function getDBUsers() {
    const response = await fetch(`${proxy}/api/utenti`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: ""
    });
    return response.json();
}

export async function getUser(id) {
    const response = await fetch(`${proxy}/api/utenti/${id}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: ""
    });
    return response.json();
}

export async function updateUtente(id, obj) {
  const response = await fetch(`${proxy}/api/utente/${id}/update`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
  });
  return response.json();
}

export async function getRichiesta(idUtente) {
  const response = await fetch(`${proxy}/api/utente/${idUtente}/richiesta`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: ""
  });
  return response.json();
}

export async function addRichiesta(idUtente,agente) {
  const response = await fetch(`${proxy}/api/utente/${idUtente}/richiesta/add`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({agente})
  });
  return response.json();
}

export async function updateRichiesta(id, richiesta) {
  const response = await fetch(`${proxy}/api/utente/${id}/richiesta/update`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(richiesta)
  });
  return response.json();
}

export async function getTipologie() {
  const response = await fetch(`${proxy}/api/immobil`);
  return response.json();
}

export async function getDimensioni() {
  const response = await fetch(`${proxy}/api/dimensioni`);
  return response.json();
}

export async function getAccessori() {
  const response = await fetch(`${proxy}/api/accessori`);
  return response.json();
}



export async function getProperties(utente) {
  const response = await fetch(`${proxy}/api/immobili/from/${utente} `, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: ""
  });
  return response.json();
}

export async function getSearch(id, richiesta) {
  //console.log(richiesta)
  const response = await fetch(`${proxy}/api/utente/${id}/proposte`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(richiesta)
  });
  return response.json();
}

export async function getDiario(id) {
  const response = await fetch(`${proxy}/api/diario/0/${id}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: ""
  });
  return response.json();
}