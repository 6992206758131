import React, { useState } from "react";
import CardImmobili from "../components/cards/CardImmobili";
import { searchProperties } from "../data/search";

const Immobili = () => {
    const [immobili, setImmobili] = useState([]);
    const search = (e) => {
        const obj = {
            codice: document.getElementById("codice").value.split("-"),
            via: document.getElementById("via").value,
            proprietario: document.getElementById("proprietario").value
        }
        if (!(obj.codice === "" && obj.via === "" && obj.proprietario === "")) {
            searchProperties(obj).then(result => setImmobili(result.immobili));
        } else console.log("vuoto")
        //console.log(immobili)
    }
    return(
        <React.Fragment>
            <div className="row mt-4">
                <h3>Cerca Immobile</h3>
                <div className="col-sm-12 col-md-4">
                    <div className="input-group mb-3">
                        <input id="codice" type="text" className="form-control" placeholder="Codice" aria-label="Codice" aria-describedby="basic-addon1" onChange={search}/>
                    </div>
                </div>
                <div className="col-sm-12 col-md-4">
                    <div className="input-group mb-3">
                        <input id="via" type="text" className="form-control" placeholder="Via" aria-label="Via" aria-describedby="basic-addon1" onChange={search}/>
                    </div>
                </div>
                <div className="col-sm-12 col-md-4">
                    <div className="input-group mb-3">
                        <input id="proprietario" type="text" className="form-control" placeholder="Proprietario" aria-label="Proprietario" aria-describedby="basic-addon1" onChange={search}/>
                    </div>
                </div>           
            </div>
            <hr />
            <div className="container mt-4">
                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-3 row-cols-lg-4 g-3">
                    {
                        immobili && immobili.map((i,k)=> {
                            return(
                                <div className="col" key={k}>
                                    <CardImmobili immobile={i}/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default Immobili