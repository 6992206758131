module.exports = {
    google: {
      API_KEY: "",
      CLIENT_ID: "940287538887-fk7cvra4fuej9rvnc8pnaa2lqa21n61j.apps.googleusercontent.com",
      SECRET: "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",
    },
    facebook: {
      APP_ID: "",
    },
    devServer: {
      // this achieves the same effect as the first example
      // with the bonus of not having to update your config
      // if new subdomains need to access the dev server
      allowedHosts: 'all',
    },
    proxy: "https://api.airimmobiliare.it"
    //proxy: ""
}
  