import React, { useEffect, useState } from "react";
import AgentCard from "../components/cards/AgentCard";
import { getAgenti } from "../data/agente";

const Agenti = () => {
    const [agenti, setAgenti] = useState([]);
    useEffect(()=> {
        getAgenti().then(result => setAgenti(result.agenti));
    },[])
    return(
        <React.Fragment>
            <div className="mt-4">
                <h3>Agenti</h3>    
            </div>
            <hr />
            <div className="container mt-4">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3">
                    {
                        agenti && agenti.map((a,i)=> {
                            return(
                                <div className="col" key={i}>
                                    <AgentCard agente={a}/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default Agenti