import { Routes, Route, useParams } from "react-router-dom";
import { useEffect } from "react";

import { addNewProperties } from "./data/immobili";
import { addNewUser } from "./data/utenti";

import Layout from "./components/layout";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Utenti from "./pages/Utenti";
import Utente from "./pages/Utente";
import Agenti from "./pages/Agenti";
import Agente from "./pages/Agente";
import Immobili from "./pages/Immobili";
import Immobile from "./pages/Immobile";

const NuovoUtente = ()=> {
    const obj = {
        agente: JSON.parse(localStorage.getItem("agente")).agente.idAgente,
    };
    useEffect(()=>{
        setTimeout(addNewUser(obj).then((result) => window.location.href = `/utenti/${result.utente.insertId}`), 2000)
    },[]);
    return(
        <>
            Creazione nuovo utente in corso...
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </>
    )
}

const NuovoImmobile = () => {
    const { id } = useParams();
    const obj = {
        agente: JSON.parse(localStorage.getItem("agente")).agente.idAgente,
        proprietario: id
    };
    useEffect(() => {
        setTimeout(addNewProperties(obj).then((result) => window.location.href = `/immobili/${result.immobile.insertId}`), 2000)
    },[]);
    return(
        <>
            Creazione nuovo immobile in corso...
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </>
    )
}

function App() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={ <Layout><Home/></Layout> } />
        <Route path="utenti" element={ <Layout><Utenti /></Layout>} />
        <Route path="utenti/:id" element={ <Layout><Utente /></Layout> } />
        <Route path="utenti/add" element={ <Layout><NuovoUtente /></Layout> } />
        <Route path="agenti" element={ <Layout><Agenti /></Layout> } />
        <Route path="agenti/:id" element={ <Layout><Agente /></Layout> } />
        <Route path="immobili" element={ <Layout><Immobili /></Layout> } />
        <Route path="immobili/:id" element={ <Layout><Immobile /></Layout> } />
        <Route path="immobili/add/:id" element={ <Layout><NuovoImmobile /></Layout> } />

        <Route path="login" element={ <Login /> } />
        <Route path="logout" element={ <Logout /> } />
        
      </Route>
    
    </Routes>
  );
}

export default App;