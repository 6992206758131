import React from "react";

const Header = (props) => {
    return (
        <React.Fragment>
          <header>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container-fluid">
                <a className="navbar-brand" href="/">Home Finder</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <a className="nav-link active" aria-current="page" href="/">Home</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link active" aria-current="page" href="/utenti">Utenti</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link active" aria-current="page" href="/immobili">Immobili</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link active" aria-current="page" href="/agenti">Agenti</a>
                    </li>                    
                  </ul>
                  
                  <div className="dropdown">
                      <label className="dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                       {props.data.user.name}
                      </label>
                      <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                        <li><a className="dropdown-item" href={`/agenti/${props.data.agente.codice}`}>Account</a></li>
                        <li><hr className="dropdown-divider"/></li>
                        <li><a className="dropdown-item" href="/logout">Logout</a></li>
                      </ul>
                    </div>
                </div>
              </div>
            </nav>
          </header>
        </React.Fragment>
      );
}

export default Header;