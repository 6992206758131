import { useEffect, useState, useCallback } from "react"
import { addRichiesta, getRichiesta, updateRichiesta } from "../data/utenti";

const Richieste = (props) => {
    const [data, setData] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    useEffect(()=> {
        getRichiesta(props.utente).then((result) => setData(result.data)).finally(()=> setLoading(false));
    },[]);

    const nuovaRichiesta = async () => {
        const agente = JSON.parse(localStorage.getItem("agente")).agente.idAgente;
        await addRichiesta(props.utente,agente).then(()=> setLoading(true))
        getRichiesta(props.utente).then((result) => setData(result.data)).finally(()=> setLoading(false));
    }
    
    const clickProvincia = (e) => {
        const value = e.target.checked;
        const idProvincia = e.target.id.split("-")[0];
        let comune;
        for(let i = 0; i < data.localita.comuni.length; i++) {
            comune = data.localita.comuni[i];
            const element = document.getElementById(idProvincia+"-"+comune.idComune+"-x");
            if (comune.provincia == idProvincia && element.checked !== value)
                element.click();
        }
    }

    const clickComune = (e) => {
        const value = e.target.checked;
        const idComune = e.target.id.split("-")[1];
        const idProvincia = e.target.id.split("-")[0];
        let zona;
        for(let i = 0; i < data.localita.zone.length; i++) {
            zona = data.localita.zone[i];
            const element = document.getElementById(idProvincia+"-"+idComune+"-"+zona.idZona);
            if (zona.comune == idComune && element.checked !== value)
                element.click();
        }
    }

    const clickZona = (e) => {
        if (data.richiesta.zone === null) data.richiesta.zone = [];
        if (e.target.checked) {            
            if (!Object.values(data.richiesta.zone).includes(e.target.id)) {
                data.richiesta.zone = (Object.values(data.richiesta.zone).concat([e.target.id]));
            }
        } else {
            data.richiesta.zone = (Object.values(data.richiesta.zone).filter((id) => {
                return id !== e.target.id;
            }))
        }
    }

    const clickTipologia = (e) => {
        if (data.richiesta.tipologia === null) data.richiesta.tipologia = [];
        if (e.target.checked) {
            if (!Object.values(data.richiesta.tipologia).includes(e.target.id.split("-")[1])) {
                data.richiesta.tipologia = (Object.values(data.richiesta.tipologia).concat([e.target.id.split("-")[1]]));
            }
        } else {
            data.richiesta.tipologia = (Object.values(data.richiesta.tipologia).filter((id) => {
                return id != e.target.id.split("-")[1];
            }))
        }
        console.log(data.richiesta.tipologia)
    }

    const clickDimensione = (e) => {
        if (data.richiesta.dimensione === null) data.richiesta.dimensione = [];
        if (e.target.checked) {
            if (!Object.values(data.richiesta.dimensione).includes(e.target.id.split("-")[1])) {
                data.richiesta.dimensione = (Object.values(data.richiesta.dimensione).concat([e.target.id.split("-")[1]]));
            }
        } else {
            data.richiesta.dimensione = (Object.values(data.richiesta.dimensione).filter((id) => {
                return id != e.target.id.split("-")[1];
            }))
        }
    }

    const clickInteresse = (e) => {
        const iteresse = e.target.id.split("-")[1];
        data.richiesta.interesse = iteresse;
        forceUpdate();
    }

    const changeRange = (e) => {
        document.getElementById("range-value").innerHTML = e.target.value;
        data.richiesta.budget = e.target.value;
    }

    const clickAccessorio = (e) => {
        if (data.richiesta.accessori === null) data.richiesta.accessori = [];
        if (e.target.checked) {
            if (!Object.values(data.richiesta.accessori).includes(e.target.id.split("-")[1])) {
                data.richiesta.accessori = (Object.values(data.richiesta.accessori).concat([e.target.id.split("-")[1]]));
            }
        } else {
            data.richiesta.accessori = (Object.values(data.richiesta.accessori).filter((id) => {
                return id != e.target.id.split("-")[1];
            }))
        }
    }

    const submit = async (e) => {
        e.preventDefault();
        data.richiesta.agente = JSON.parse(localStorage.getItem("agente")).agente.idAgente;
        const update = await updateRichiesta(props.utente, data.richiesta);
        console.log(update);
        if (!update.ok) {
        /*    const element = document.getElementsByName(update.error.field)[0]
            console.log(element)
            element.className +=" is-invalid"
            const root = element.parentElement
            const div = document.createElement("div");
            div.className = "invalid-feedback";
            div.innerHTML  = update.error.message;
            root.appendChild(div);*/
        }
    }
    if (isLoading) return "Loading...";

    //console.log(data)
    if (data.richiesta)
    return(
        <form onSubmit={submit}>
            <div className="accordion accordion-flush" id="accordionFlushExample">
                {
                    data.richiesta && data.localita.province!== undefined && data.localita.province.map((p,i) => {
                        return(

                            <div className="accordion-item" key={i}>
                                <h2 className="accordion-header" id="flush-headingOne">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapse"+p.nome} aria-expanded="false" aria-controls="flush-collapseOne">
                                        {p.nome}
                                    </button>
                                </h2>
                                <div id={"flush-collapse"+p.nome} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        seleziona tutto <input type="checkbox" className="form-check-input" id={p.idProvincia+"-x-x"} onChange={clickProvincia}/>
                                        <hr />
                                        <div className="container">
                                            <div className="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
                                                {
                                                    data.richiesta && data.localita.comuni !== undefined && data.localita.comuni.map((c,j) => {
                                                        if (c.provincia == p.idProvincia)
                                                        return(
                                                            <div className="col" key={j}>
                                                                <div className="card">
                                                                    <div className="card-header"> 
                                                                        <div className="row">
                                                                            <div className="col-10">
                                                                                <label className="form-check-label" >{c.nome}</label>
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <input type="checkbox" className="form-check-input" id={c.provincia+"-"+c.idComune+"-x"} onClick={clickComune}/>
                                                                            </div>
                                                                            

                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <ul className="list-group list-group-flush">
                                                                        {
                                                                            data.richiesta && data.localita.zone !== undefined && data.localita.zone.map((z,k)=> {
                                                                                if (c.idComune == z.comune)
                                                                                return(
                                                                                    <li className="list-group-item" key={k}>
                                                                                        <div className="row">
                                                                                            <div className="col-8">
                                                                                                <label className="form-check-label" >{z.nome}</label>
                                                                                            </div>
                                                                                            <div className="col-4">
                                                                                                <input type="checkbox" className="form-check-input" id={z.provincia+"-"+z.comune+"-"+z.idZona} onClick={clickZona} defaultChecked={data.richiesta.zone ? Object.values(data.richiesta.zone).includes(z.provincia+"-"+z.comune+"-"+z.idZona) : false} />
                                                                                            </div>
                                                                                            
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                
                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        
                    })
                }
                
                
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-6 mt-3">
                    <div className="card">
                        <div className="card-header">
                            Tipologia
                        </div>
                        <ul className="list-group list-group-flush">
                            {
                                data.richiesta && data.tipologie && data.tipologie.map((t,k) => {
                                    return(
                                        <li className="list-group-item" key={k}>
                                            <div className="row">
                                                <div className="col-9">
                                                    <label className="form-check-label" >{t.nome}</label>
                                                </div>
                                                <div className="col-3">
                                                    <input type="checkbox" className="form-check-input" id={"imm-"+t.idImmobile} onChange={clickTipologia} defaultChecked={data.richiesta.tipologia ? data.richiesta.tipologia.includes(t.idImmobile.toString()) : false} />
                                                    
                                                </div>
                                                
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 mt-3">
                    <div className="card">
                        <div className="card-header">
                            Dimensione
                        </div>
                        <ul className="list-group list-group-flush">
                        {
                                data.richiesta && data.dimensioni && data.dimensioni.map((d,k) => {
                                    return(
                                        <li className="list-group-item" key={k}>
                                            <div className="row">
                                                <div className="col-9">
                                                    <label className="form-check-label" >{d.nome}</label>
                                                </div>
                                                <div className="col-3">
                                                    <input type="checkbox" className="form-check-input" id={"dim-"+d.idDimensione} onChange={clickDimensione} defaultChecked={data.richiesta.dimensione ? data.richiesta.dimensione.includes(d.idDimensione.toString()) : false}/>
                                                </div>
                                                
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-6 mt-3">
                   <label className="form-label">Budget <span></span></label>
                    <input type="range" className="form-range" min="0" max="2000000" step="10000" id="prezzo" onChange={changeRange} value={data.richiesta.budget ? data.richiesta.budget : 0} /> 
                        <label id="range-value">{data.richiesta.budget}</label>
                </div>
                <div className="col-sm-12 col-md-6 mt-3">
                    <label>Interesse a: </label>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="interesse" id="interesse-0" onChange={clickInteresse} checked={data.richiesta.interesse == 0 ? true : false}/>
                        <label className="form-check-label" >
                            Acquisto
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="interesse" id="interesse-1" onChange={clickInteresse} checked={data.richiesta.interesse == 1 ? true : false}/>
                        <label className="form-check-label">
                            Affitto 
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="interesse" id="interesse-2" onChange={clickInteresse} checked={data.richiesta.interesse == 2 ? true : false }/>
                        <label className="form-check-label">
                            Entrambi
                        </label>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-6 mt-3">
                    <div className="card">
                        <div className="card-header">
                            Accessori abitativi
                        </div>
                        <ul className="list-group list-group-flush">
                            {
                                data.richiesta && data.accessori && data.accessori.map((a,k) => {
                                    if (a.abitativo)
                                    return(
                                        <li className="list-group-item" key={k}>
                                            <div className="row">
                                                <div className="col-9">
                                                    <label className="form-check-label" >{a.nome}</label>
                                                </div>
                                                <div className="col-3">
                                                    <input type="checkbox" className="form-check-input" id={"acc-"+a.idAccessorio} onClick={clickAccessorio} defaultChecked={data.richiesta.accessori ? data.richiesta.accessori.includes(a.idAccessorio.toString()) : false}/>
                                                </div>
                                                
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 mt-3">
                    <div className="card">
                        <div className="card-header">
                            Accessori non abitativi
                        </div>
                        <ul className="list-group list-group-flush">
                            {
                                data.richiesta && data.accessori && data.accessori.map((a,k) => {
                                    if (!a.abitativo)
                                    return(
                                        <li className="list-group-item" key={k}>
                                            <div className="row">
                                                <div className="col-9">
                                                    <label className="form-check-label" >{a.nome}</label>
                                                </div>
                                                <div className="col-3">
                                                    <input type="checkbox" className="form-check-input" id={"acc-"+a.idAccessorio} onClick={clickAccessorio} defaultChecked={data.richiesta.accessori ? data.richiesta.accessori.includes(a.idAccessorio.toString()) : false}/>
                                                </div>
                                                
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>

            <button className="btn btn-primary w-100 mt-3" type="submit" >Aggiorna</button>
        </form>
    )
    else
    return(
        <button className="btn btn-primary" onClick={nuovaRichiesta}>Nuova Richiesta</button>
    )

}
export default Richieste;