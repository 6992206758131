import React, { useState } from "react"
import { google } from "../config";
import { getIp, jwtDec, loginUser } from "../data/login";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';


const ErrorMessage = (props) => {
    return(
        <div className="alert alert-danger d-flex align-items-center" role="alert">
            <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"></svg>
            <div>
                {props.message}
            </div>
        </div>
    )
}



const Login = () => {
    const [error, setError] = useState(null);
    
    
    const res = async (e)=> {
        //console.log(e);
        const data = await jwtDec(e.credential)
        //console.log("a")
        const ip = await getIp();
        //console.log(e)
        //console.log(data)
        //alert("ok")

        const user = {
            email: data.email,
            name: data.name,
            token: data.jti,
            img: data.picture,
            ip: ip.IPv4,
            useragent: window.navigator.userAgent
        }
        //console.log("c")
        const result = await loginUser(user);
        //console.log("d")
        if (!result.ok) setError(result.error)
        else {
            localStorage.setItem("agente", JSON.stringify({agente: result.agente, user: user}));
            //console.log("e")
            //console.log(localStorage.getItem("agente"));
            window.location.href = '/';
        }   
    }
    
    return(
        <React.Fragment>
            <div className="container col-xl-10 col-xxl-8 px-4 py-5">
                <div className="row align-items-center g-lg-5 py-5">
                    <div className="col-lg-7 text-center text-lg-start">
                        <h1 className="display-4 fw-bold lh-1 mb-3">AIR IMMOBILIARE</h1>
                        <p className="col-lg-10 fs-4">Accedi con google per entrare nel gestionale</p>
                        <p className="col-lg-10 fs-4 text-muted">@HomeFinder</p>
                    </div>
                    <div className="col-md-10 mx-auto col-lg-5">
                        <form className="p-4 p-md-5 border rounded-3 bg-light">
                            
                            { error ? <ErrorMessage message={error} /> : null }                              
                                
                            <GoogleOAuthProvider clientId={google.CLIENT_ID}>
                            <GoogleLogin
                                onSuccess={res}
                                onError={() => {
                                    alert('Login Fallito');
                                }}
                                
                            />
                            </GoogleOAuthProvider>
                            
                            <hr className="my-4" />
                        </form>
                    </div>
                </div>
                
                
            </div>
        </React.Fragment>
    )
}

export default Login