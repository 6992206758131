import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { deleteDoc, deleteImg, downloadDoc, getImg, swapImgIndex, updateImmobile, uploadDoc, uploadImg, getData } from "../data/immobili";
import CardImmobile from "../components/cards/CardImmobile";

const Immobile = () => {
    const { id } = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(()=> {       
        getData(id)
        .then(result => setData(result.data))
        .finally(()=> setLoading(false))
    },[]);

    if (loading) return "Loading...";

    const Galleria = () => {
        const [foto, setFoto] = useState([]);
        const [loading, setLoading] = useState(true);
        useEffect(()=>{
            getImg(id, undefined)
            .then(response => response.json())
            .then(result => setFoto(result.immagini.map(f => f.nome)))
            .finally(()=> setLoading(false))
        },[])

        if (loading) return "Loading...";

        const Image = (props) => {
            const [url, setUrl] = useState([]);
            const [loading, setLoading] = useState(true);
            useEffect(() => {
                getImg(id,props.nome)
                .then(response => { return response.blob() })
                .then((blob) => setUrl(URL.createObjectURL(blob)))
                .finally(()=> setLoading(false))
            },[])
            if (loading) return "Loading...";

            return(
                <img src={url} alt={url} />
            )
        }
        const swapImgBefore = async (e) => {
            e.preventDefault();
            const index = e.target.attributes.index.value
            if (index != 0) {
                const obj = {
                    nome: e.target.name,
                    index: index,
                    next: parseInt(index)-1
                }
                setLoading(true)
                await swapImgIndex(id,obj)
                getImg(id, undefined)
                .then(response => response.json())
                .then(result => setFoto(result.immagini.map(f => f.nome)))
                .finally(()=> setLoading(false))
            }
        }

        const swapImgAfter = async (e) => {
            e.preventDefault();
            const index = e.target.attributes.index.value
            if (index != foto.length) {
                const obj = {
                    nome: e.target.name,
                    index: index,
                    next: parseInt(index)+1
                }
                setLoading(true)
                await swapImgIndex(id,obj)
                getImg(id, undefined)
                .then(response => response.json())
                .then(result => setFoto(result.immagini.map(f => f.nome)))
                .finally(()=> setLoading(false))
            }
        }

        const delImg = async (e) => {
            e.preventDefault();
            const index = e.target.attributes.index.value
            const obj = {
                immobile: id,
                index: index,
                nome: e.target.name,
            };   
            setLoading(true)
            await deleteImg(id,obj);    
            getImg(id, undefined)
            .then(response => response.json())
            .then(result => setFoto(result.immagini.map(f => f.nome)))
            .finally(()=> setLoading(false))
        }

        return(
            <div className="row row-cols-2 row-cols-lg-4 g-2 g-lg-3 mt-2 mb-2">
                {
                    foto && foto.map((f,i) => {
                        return(
                            <div className="col" key={i}>
                                <div className="card">
                                    <Image nome={f} index={i}/>
                                    <div className="card-body text-center">
                                        <button className="btn bi bi-arrow-left-circle" onClick={swapImgBefore} index={i} name={f}></button> {i} <button className="btn bi bi-arrow-right-circle" onClick={swapImgAfter} index={i} name={f}></button>
                                        <button className="btn bi bi-trash3 text-danger" onClick={delImg} index={i} name={f}></button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                    
            </div>
        )
    }

    const submit = async (e) => {
        if (e !== undefined) e.preventDefault();
        //console.log(immobile)
        if (data.immobile.agente != JSON.parse(localStorage.getItem("agente")).agente.idAgente) {
            alert('non sei autorizzato a modifiare questi dati');
            return;
        }

        const update = await updateImmobile(data.immobile);
        console.log(update);
    }

    const editOnline = (e) => {
        data.immobile[e.target.name] = e.target.checked;
        submit();
        forceUpdate();
    }
    
    const edit = async (e) => {
        const element = e.target;
        if (element.type === "radio") {
            switch (element.id) {
                case "iec":
                    data.immobile[element.name] = 1;
                break;
                case "ienc":
                    data.immobile[element.name] = 0;
                break;
                case "itc":
                    data.immobile[element.name] = 1;
                break;
                case "itnc":
                    data.immobile[element.name] = 0;
                break;
                case "iv":
                    data.immobile[element.name] = 0;
                break;
                case "ia":
                    data.immobile[element.name] = 1;
                break;
                case "ie":
                    data.immobile[element.name] = 2;
                break;
                case "tc":
                    data.immobile[element.name] = 1;
                break;
                case "tnc":
                    data.immobile[element.name] = 0;
                break;
                case "ec":
                    data.immobile[element.name] = 1;
                break;
                case "enc":
                    data.immobile[element.name] = 0;
                break;
            }
        } else if (element.type === "checkbox") data.immobile[element.name] = element.checked ? 1 : 0;
        else data.immobile[element.name] = element.value;

        if (element.name == "certificazione") {
            //immobile.certificazione = null;
            data.immobile.classe = null;
            const update = await updateImmobile(data.immobile);
            getData(id)
            .then(result => setData(result.data))
        }
        if (element.name!== "annuncio") forceUpdate();
    }

    const clickAccessorio = (e) => {
        if (data.immobile.accessori === null) data.immobile.accessori = [];
        if (e.target.checked) {
            if (!Object.values(data.immobile.accessori).includes(e.target.id.split("-")[1])) {
                data.immobile.accessori = (Object.values(data.immobile.accessori).concat([e.target.id.split("-")[1]]));
            }
        } else {
            data.immobile.accessori = (Object.values(data.immobile.accessori).filter((id) => {
                return id != e.target.id.split("-")[1];
            }))
        }
    }

    const doc = async (e) => {
        const form = new FormData();
        form.append('nome', e.target.id);
        form.append('file', e.target.files[0]);
        await uploadDoc(id, form);
        getData(id).then(result => setData(result.data))
    }

    const img = (e) => {
        //console.log(e.target.files)
        const files = Array.from(e.target.files)
        files.map(async (file, index) => {
            const form = new FormData();
            console.log(parseInt(data.foto ? data.foto : 0) + index)
            form.append('index', parseInt(data.foto ? data.foto : 0) + index);
            form.append('file', file);
            form.append('immobile', id);
            await uploadImg(id,form);
        })
        getData(id).then(result => setData(result.data))
        
    }

    const scaricaDoc = async (e) => {
        const obj = {
            immobile: id,
            nome: e.target.name
        }
        const blob = await downloadDoc(id, obj);
        const url =  URL.createObjectURL(blob);
        window.open(url, '_blank').focus();
    }

    const eliminaDoc = async (e) => {
        const obj = {
            immobile: id,
            nome: e.target.name
        }
        await deleteDoc(id, obj);
        getData(id).then(result => setData(result.data))
    }
    if (!loading)
    return(
        <React.Fragment>
            <div className="row mt-5">
                <div className="col-sm-12 col-md-6 col-lg-4">
                    {
                        data.preview ? <CardImmobile immobile={data.preview} /> : null
                    }
                </div>
                <div className="col-sm-12 col-md-6 col-lg-8 mt-3">
                    <form className="border p-4" onSubmit={submit}>
                        { data.proprietario ? data.proprietario.nome : null} 
                        <div className="input-group mb-3">
                            <select className="form-select" aria-label="Default select example" name="tipovia" onChange={edit} value={data.immobile.tipovia} >
                                <option>Tipo Indirizzo</option>
                                {
                                    data.indirizzo && data.indirizzo.map((i,k) => {
                                        return(
                                            <option value={i.idIndirizzo} key={k}>{i.tipo}</option>
                                        )
                                    })
                                }
                            </select>
                            <input type="text" className="form-control w-50" name="via" onChange={edit} defaultValue={data.immobile.via} placeholder="Via" aria-label="Via" aria-describedby="basic-addon1" data-bs-toggle="tooltip" data-bs-placement="top" title="Via"/>
                            <input type="text" className="form-control" name="civico" onChange={edit} defaultValue={data.immobile.civico} placeholder="Civico" aria-label="Civico" aria-describedby="basic-addon1" data-bs-toggle="tooltip" data-bs-placement="top" title="Civico"/>
                        </div>
                        
                        <div className="input-group mb-3">
                            <select className="form-select" aria-label="Default select example" name="provincia" onChange={edit} value={data.immobile.provincia !== null ? data.immobile.provincia : ""}>
                                <option>Provincia</option>
                                {
                                    data.localita.province && data.localita.province.map((p,k) => {
                                        return(
                                            <option value={p.idProvincia} key={k}>{p.nome}</option>
                                        )
                                    })
                                }
                            </select>
                            <select className="form-select" aria-label="Default select example" name="comune" onChange={edit} value={data.immobile.comune !== null ? data.immobile.comune : ""}>
                                <option>Comune</option>
                                {
                                    data.immobile.provincia && data.localita.comuni && data.localita.comuni.map((c,k) => {
                                        if (data.immobile.provincia == c.provincia)
                                        return(
                                            <option value={c.idComune} key={k}>{c.nome}</option>
                                        )
                                    })
                                }
                            </select>
                            <select className="form-select" aria-label="Default select example" name="zona" onChange={edit} value={data.immobile.zona !== null ? data.immobile.zona : ""}>
                                <option>Zona</option>
                                {
                                    data.immobile.comune && data.localita.zone && data.localita.zone.map((z,k) => {
                                        if (data.immobile.comune == z.comune)
                                        return(
                                            <option value={z.idZona} key={k}>{z.nome}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    

                        <div className="mb-3">
                            <button className="btn btn-secondary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                Note
                            </button>
                            <a className="btn btn-primary m-2" type="button" href={`/utenti/${data.immobile.proprietario}`}>
                                Proprietario
                            </a>
                            <div className="collapse mt-3" id="collapseExample">
                                <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" name="note" onChange={edit} defaultValue={data.immobile.note}></textarea>
                            </div>
                        </div>

                        <div className="mb-2">
                            <button type="submit" className="btn btn-primary w-100">Aggiorna</button>
                        </div>
                    </form>
                </div>
            </div>

            <div className="p-4 bg-light rounded-3 mt-3">
                <h4 className="fw-bold">Proposta</h4>
                <form className="" onSubmit={submit}>
                    <div className="row ">
                        <div className="col"></div>
                        <div className="col"><label className="form-label">Data costruzione</label></div>
                    </div>
                        
                    <div className="row ">
                        <div className="col mb-3">
                            <select className="form-select" aria-label="Default select example" name="fabbricato" onChange={edit} value={data.immobile.fabbricato !== null ? data.immobile.fabbricato : ""} data-bs-toggle="tooltip" data-bs-placement="top" title="Tipologia Fabbricato">
                                <option>Tipo Fabbricato</option>
                                {
                                    data.fabbricato && data.fabbricato.map((f,k) => {
                                        return(
                                            <option value={f.idFabbricato} key={k}>{f.nome}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col mb-3">
                            <input type="date" className="form-control" name="costruzione" onChange={edit} defaultValue={data.immobile.costruzione ? data.immobile.costruzione : ""}/> 
                        </div>
                    </div>

                    <div className="row ">
                        <div className="col-6 mb-3">
                            <div className="input-group">
                                <input type="number" className="form-control" name="piani" onChange={edit} defaultValue={data.immobile.piani} placeholder="Piani Fabbricato" aria-label="Piani" aria-describedby="basic-addon1" data-bs-toggle="tooltip" data-bs-placement="top" title="Piani Fabbricato"/>
                            </div>
                        </div>
                        <div className="col-6 mb-3">
                            <select className="form-select" aria-label="Default select example" name="condizione" onChange={edit} value={data.immobile.condizione !== null ? data.immobile.condizione : ""} data-bs-toggle="tooltip" data-bs-placement="top" title="Condizioni Fabbricato">
                                <option>Condizioni Fabbricato</option>
                                {
                                    data.condizioni && data.condizioni.map((c,k) => {
                                        return(
                                            <option value={c.idCondizione} key={k}>{c.nome}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    <div className="row ">
                        <div className="col-sm-12 col-md-6 mb-3">
                            <select className="form-select" aria-label="Default select example" name="immobile" onChange={edit} value={data.immobile.immobile !== null ? data.immobile.immobile : ""} data-bs-toggle="tooltip" data-bs-placement="top" title="Tipologia Immobile">
                                <option>Tipologia Immobile</option>
                                {
                                    data.immobili && data.immobili.map((i,k) => {
                                        return(
                                            <option value={i.idImmobile} key={k}>{i.nome}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-3">
                            <div className="input-group ">
                                <input type="number" className="form-control " name="camere" onChange={edit} defaultValue={data.immobile.camere} placeholder="Camere" aria-label="Camere" aria-describedby="basic-addon1" data-bs-toggle="tooltip" data-bs-placement="top" title="Camere"/>
                                <input type="number" className="form-control " name="letti" onChange={edit} defaultValue={data.immobile.letti} placeholder="Camere da letto" aria-label="Camere da letto" aria-describedby="basic-addon1" data-bs-toggle="tooltip" data-bs-placement="top" title="Letti"/>
                            </div>
                        </div>
                    </div>

                    <div className="row ">
                        
                        <div className="col-sm-12 col-md-6 mb-3">
                            <div className="input-group">
                                <input type="number" className="form-control " name="superficie" onChange={edit} defaultValue={data.immobile.superficie} placeholder="Superficie" aria-describedby="basic-addon1" data-bs-toggle="tooltip" data-bs-placement="top" title="Superficie"/>
                                <input type="number" className="form-control " name="bagni" onChange={edit} defaultValue={data.immobile.bagni} placeholder="Bagni" aria-describedby="basic-addon1" data-bs-toggle="tooltip" data-bs-placement="top" title="Bagni"/>
                                <input type="number" className="form-control " name="piano" onChange={edit} defaultValue={data.immobile.piano} placeholder="Piano" aria-describedby="basic-addon1" data-bs-toggle="tooltip" data-bs-placement="top" title="Piano"/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-3 ">
                            <div className=" form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" name="ascensore" onChange={edit} defaultChecked={data.immobile.ascensore} />
                                <label className="form-check-label">Ascensore</label>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-6 mb-3">
                            <div className="card">
                                <div className="card-header">
                                    Accessori abitativi
                                </div>
                                <ul className="list-group list-group-flush">
                                    {
                                        data.accessori && data.accessori.map((a,k) => {
                                            if (a.abitativo)
                                            return(
                                                <li className="list-group-item" key={k}>
                                                    <div className="row">
                                                        <div className="col-9">
                                                            <label className="form-check-label" >{a.nome}</label>
                                                        </div>
                                                        <div className="col-3">
                                                            <input type="checkbox" className="form-check-input" id={"acc-"+a.idAccessorio} onClick={clickAccessorio} defaultChecked={data.immobile.accessori ? data.immobile.accessori.includes(a.idAccessorio.toString()) : false} />
                                                        </div>
                                                        
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-3">
                            <div className="card">
                                <div className="card-header">
                                    Accessori non abitativi
                                </div>
                                <ul className="list-group list-group-flush">
                                    {
                                        data.accessori && data.accessori.map((a,k) => {
                                            if (!a.abitativo)
                                            return(
                                                <li className="list-group-item" key={k}>
                                                    <div className="row">
                                                        <div className="col-9">
                                                            <label className="form-check-label" >{a.nome}</label>
                                                        </div>
                                                        <div className="col-3">
                                                            <input type="checkbox" className="form-check-input" id={"acc-"+a.idAccessorio} onClick={clickAccessorio} defaultChecked={data.immobile.accessori ? data.immobile.accessori.includes(a.idAccessorio.toString()) : false}/>
                                                        </div>
                                                        
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                        
                    <div className="row ">
                        <div className="col-sm-6 col-md-6 mb-3 ">
                            <select className="form-select" aria-label="Default select example" name="riscaldamento" onChange={edit} value={data.immobile.riscaldamento !== null ? data.immobile.riscaldamento : ""}>
                                <option>Impianto Riscaldamento</option>
                                {
                                    data.riscaldamento && data.riscaldamento.map((i,k) => {
                                        return(
                                            <option value={i.idRiscaldamento} key={k}>{i.nome}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-sm-6 col-md-6 mb-3">
                            <div className="input-group">
                                <select className="form-select" aria-label="Default select example" name="certificazione" onChange={edit} value={data.immobile.certificazione !== null ? data.immobile.certificazione : ""}>
                                    <option>Certificazione</option>
                                    <option value="0">ACE</option>
                                    <option value="1">APE</option>
                                </select>
                                <select className="form-select" aria-label="Default select example" name="classe" onChange={edit} value={data.immobile.classe !== null ? data.immobile.classe : ""}>
                                    <option>Classe</option>
                                    {
                                        data.classi && data.classi.map((c,k) => {
                                            if (data.immobile.certificazione !== null) {
                                                if (data.immobile.certificazione == c.certificazione)
                                                    return(
                                                        <option value={c.idClasse} key={k}>{c.nome}</option>
                                                    )
                                            } else {
                                                return(
                                                    <option value={c.idClasse} key={k}>{c.nome}</option>
                                                )
                                            }
                                            
                                        })
                                    }
                                </select>
                                <input type="number" className="form-control " placeholder="ep/epgl" aria-describedby="basic-addon1" name="epgl" onChange={edit} defaultValue={data.immobile.epgl} />
                            </div>
                        </div>
                    </div>

                    <div className="row" >
                        <div className="col mb-3">
                            <label>Impianto elettrico</label>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="impiantoelettrico" id="iec" onChange={edit}  checked={data.immobile.impiantoelettrico == 1 ? true : false } />
                                <label className="form-check-label" >
                                    Conforme
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="impiantoelettrico" id="ienc" onChange={edit} checked={data.immobile.impiantoelettrico == 0 ? true : false } />
                                <label className="form-check-label">
                                    Non conforme
                                </label>
                            </div>
                        </div>
                        <div className="col mb-3">
                            <label>Impianto termico</label>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="impiantotermico" id="itc" onChange={edit} checked={data.immobile.impiantotermico == 1 ? true : false }/>
                                <label className="form-check-label" >
                                    Conforme
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="impiantotermico" id="itnc" onChange={edit} checked={data.immobile.impiantotermico == 0 ? true : false }/>
                                <label className="form-check-label">
                                    Non conforme
                                </label>
                            </div>  
                        </div>
                    </div>
                    <h6>Prezzo</h6>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 mb-3">
                            <label>Interesse a:</label>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="interesse" id="iv" onChange={edit} checked={data.immobile.interesse == 0 ? true : false} />
                                <label className="form-check-label" >
                                    Vendita
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="interesse" id="ia" onChange={edit} checked={data.immobile.interesse == 1 ? true : false} />
                                <label className="form-check-label">
                                    Affitto
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="interesse" id="ie" onChange={edit} checked={data.immobile.interesse == 2 ? true : false} />
                                <label className="form-check-label">
                                    Entrambi
                                </label>
                            </div>
                            
                        </div>
                        <div className="col-sm-12 col-md-6 mb-3">
                            <div className="input-group"> 
                                <input type="number" className="form-control " name="proposta" onChange={edit} defaultValue={data.immobile.proposta} placeholder="proposta" aria-describedby="basic-addon1" data-bs-toggle="tooltip" data-bs-placement="top" title="Proposta"/>
                                <input type="number" className="form-control " name="stima" onChange={edit} defaultValue={data.immobile.stima} placeholder="stima" aria-describedby="basic-addon1" data-bs-toggle="tooltip" data-bs-placement="top" title="Stima"/>
                                <input type="number" className="form-control " name="pubblicita" onChange={edit} defaultValue={data.immobile.pubblicita} placeholder="pubblicita" aria-describedby="basic-addon1" data-bs-toggle="tooltip" data-bs-placement="top" title="Pubblicita"/>
                            </div>
                        </div>
                    </div>

                    <div className="row">  
                        <div className="col col-md-4 mb-3">
                            <label>Trattabile</label>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="trattabile" id="tc" onChange={edit} checked={data.immobile.trattabile == 1 ? true : false } />
                                <label className="form-check-label" >
                                    Si
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="trattabile" id="tnc" onChange={edit} checked={data.immobile.trattabile == 0 ? true : false }/>
                                <label className="form-check-label">
                                    No
                                </label>
                            </div>
                        </div>
                        <div className="col col-md-4 mb-3">
                            <label>Esclusiva</label>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="esclusiva" id="ec" onChange={edit} checked={data.immobile.esclusiva == 1 ? true : false } />
                                <label className="form-check-label" >
                                    Si
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="esclusiva" id="enc" onChange={edit} checked={data.immobile.esclusiva == 0 ? true : false } />
                                <label className="form-check-label">
                                    No
                                </label>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 mb-3">
                            <div className="input-group">
                                <input className="form-control " placeholder="provvigione% + IVA" aria-describedby="basic-addon1" name="provvigione" onChange={edit} defaultValue={data.immobile.provvigione} />
                            </div>
                        </div>
                    </div>
                        
                    <div className="mb-2">
                        <button type="submit" className="btn btn-primary w-100">Aggiorna</button>
                    </div>
                </form>

            </div>

            <div className="p-4 bg-light rounded-3 mt-3">
                <h4 className="fw-bold">Documenti</h4>
                <div className="row">
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Provenienza                                
                                {
                                    data.documenti && data.documenti.includes('atto_di_provenienza') && (
                                        <>
                                            <button className="btn bi bi-eye text-success" ></button>
                                            <button className="btn bi bi-download text-primary" name={`atto_di_provenienza`} onClick={scaricaDoc}></button>
                                            <button className="btn bi bi-x-square-fill text-danger" name={`atto_di_provenienza`} onClick={eliminaDoc}></button>
                                        </>
                                    )
                                }

                                {
                                    data.documenti && !data.documenti.includes('atto_di_provenienza') && (
                                        <>
                                            <button className="btn bi bi-upload text-primary" onClick={() => document.getElementById("atto_di_provenienza").click()}></button>            
                                        </>
                                    )
                                }
                                <input id="atto_di_provenienza" type="file" onChange={doc} hidden/>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Catasto
                                {
                                    data.documenti && data.documenti.includes('catasto') && (
                                        <>
                                            <button className="btn bi bi-eye text-success" ></button>
                                            <button className="btn bi bi-download text-primary" name={`catasto`} onClick={scaricaDoc}></button>
                                            <button className="btn bi bi-x-square-fill text-danger" name={`catasto`} onClick={eliminaDoc}></button>
                                        </>
                                    )
                                }

                                {
                                    data.documenti && !data.documenti.includes('catasto') && (
                                        <>
                                            <button className="btn bi bi-upload text-primary" onClick={() => document.getElementById("catasto").click()}></button>            
                                        </>
                                    )
                                }
                                <input id="catasto" type="file" onChange={doc} hidden/>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Urbanistica
                                {
                                    data.documenti && data.documenti.includes('urbaistica') && (
                                        <>
                                            <button className="btn bi bi-eye text-success" ></button>
                                            <button className="btn bi bi-download text-primary" name={`urbaistica`} onClick={scaricaDoc}></button>
                                            <button className="btn bi bi-x-square-fill text-danger" name={`urbaistica`} onClick={eliminaDoc}></button>
                                        </>
                                    )
                                }

                                {
                                    data.documenti && !data.documenti.includes('urbaistica') && (
                                        <>
                                            <button className="btn bi bi-upload text-primary" onClick={() => document.getElementById("urbaistica").click()}></button>            
                                        </>
                                    )
                                }
                                <input id="urbaistica" type="file" onChange={doc} hidden/>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                APE
                                {
                                    data.documenti && data.documenti.includes('ape') && (
                                        <>
                                            <button className="btn bi bi-eye text-success" ></button>
                                            <button className="btn bi bi-download text-primary" name={`ape`} onClick={scaricaDoc}></button>
                                            <button className="btn bi bi-x-square-fill text-danger" name={`ape`} onClick={eliminaDoc}></button>
                                        </>
                                    )
                                }

                                {
                                    data.documenti && !data.documenti.includes('ape') && (
                                        <>
                                            <button className="btn bi bi-upload text-primary" onClick={() => document.getElementById("ape").click()}></button>            
                                        </>
                                    )
                                }
                                <input id="ape" type="file" onChange={doc} hidden/>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                RTI
                                {
                                    data.documenti && data.documenti.includes('rti') && (
                                        <>
                                            <button className="btn bi bi-eye text-success" ></button>
                                            <button className="btn bi bi-download text-primary" name={`rti`} onClick={scaricaDoc}></button>
                                            <button className="btn bi bi-x-square-fill text-danger" name={`rti`} onClick={eliminaDoc}></button>
                                        </>
                                    )
                                }

                                {
                                    data.documenti && !data.documenti.includes('rti') && (
                                        <>
                                            <button className="btn bi bi-upload text-primary" onClick={() => document.getElementById("rti").click()}></button>            
                                        </>
                                    )
                                }
                                <input id="rti" type="file" onChange={doc} hidden/>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Mandato
                                {
                                    data.documenti && data.documenti.includes('mandato') && (
                                        <>
                                            <button className="btn bi bi-eye text-success" ></button>
                                            <button className="btn bi bi-download text-primary" name={`mandato`} onClick={scaricaDoc}></button>
                                            <button className="btn bi bi-x-square-fill text-danger" name={`mandato`} onClick={eliminaDoc}></button>
                                        </>
                                    )
                                }

                                {
                                    data.documenti && !data.documenti.includes('mandato') && (
                                        <>
                                            <button className="btn bi bi-upload text-primary" onClick={() => document.getElementById("mandato").click()}></button>            
                                        </>
                                    )
                                }
                                <input id="mandato" type="file" onChange={doc} hidden/>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Antiriciclaggio e Privacy
                                {
                                    data.documenti && data.documenti.includes('antiriciclaggio') && (
                                        <>
                                            <button className="btn bi bi-eye text-success" ></button>
                                            <button className="btn bi bi-download text-primary" name={`antiriciclaggio`} onClick={scaricaDoc}></button>
                                            <button className="btn bi bi-x-square-fill text-danger" name={`antiriciclaggio`} onClick={eliminaDoc}></button>
                                        </>
                                    )
                                }

                                {
                                    data.documenti && !data.documenti.includes('antiriciclaggio') && (
                                        <>
                                            <button className="btn bi bi-upload text-primary" onClick={() => document.getElementById("antiriciclaggio").click()}></button>            
                                        </>
                                    )
                                }
                                <input id="antiriciclaggio" type="file" onChange={doc} hidden/>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Varie
                                {
                                    data.documenti && data.documenti.includes('varie') && (
                                        <>
                                            <button className="btn bi bi-eye text-success" ></button>
                                            <button className="btn bi bi-download text-primary" name={`varie`} onClick={scaricaDoc}></button>
                                            <button className="btn bi bi-x-square-fill text-danger" name={`varie`} onClick={eliminaDoc}></button>
                                        </>
                                    )
                                }

                                {
                                    data.documenti && !data.documenti.includes('varie') && (
                                        <>
                                            <button className="btn bi bi-upload text-primary" onClick={() => document.getElementById("varie").click()}></button>            
                                        </>
                                    )
                                }
                                <input id="varie" type="file" onChange={doc} hidden/>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-8">
                        
                    </div>
                </div>

            </div>

            <div className="p-4 bg-light rounded-3 mt-3">
                <h4 className="fw-bold">Annuncio</h4>
                <form className="mb-3" onSubmit={submit}>
                    <h5>Foto</h5>
                    <div className="mb-3">
                        <input className="form-control form-control-lg" type="file" onChange={img} multiple/>
                        <Galleria />
                    </div>
                    
                    <textarea className="form-control mb-3" placeholder="Breve descrizione dell'immobile da inserire nell'annuncio" id="" name="annuncio" defaultValue={data.immobile.annuncio} onChange={edit}></textarea>
                    <button type="submit" className="btn btn-primary w-100">Aggiorna</button>
                </form>
                
                <div className="row">
                    <div className="col-8">
                        <h4>Pubblica</h4>
                    </div>
                    <div className="col-4">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" role="switch" name="pubblico" onChange={editOnline} checked={data.immobile.pubblico} />
                            <label className="form-check-label">online</label>
                        </div>
                    </div>
                </div>
                <div className="mt-2 row row-cols-1 row-cols-lg-5 g-3 g-lg-3">
                    <div className="col">
                        <div className="p-3 border">Sito</div>
                    </div>
                    <div className="col">
                        <div className="p-3 border ">Immobiliare</div>
                    </div>
                    <div className="col">
                        <div className="p-3 border">Subito</div>
                    </div>
                    <div className="col">
                        <div className="p-3 border">Casa.it</div>
                    </div>
                    <div className="col">
                        <div className="p-3 border">Idealista</div>
                    </div>
                    <div className="col">
                        <div className="p-3 border">Facebook</div>
                    </div>
                    <div className="col">
                        <div className="p-3 border">Instagram</div>
                    </div>
                    <div className="col">
                        <div className="p-3 border ">Cartello</div>
                    </div>
                </div>
                
            </div>

            <div className="p-4 bg-light rounded-3 mt-3">
                <h4>Diario</h4>
                {
                    data.diario && data.diario.map((d,i) => {
                        return(
                            <div className="alert alert-primary" role="alert" key={i}>
                                <p className="text-break" dangerouslySetInnerHTML={{ __html: d.descrizione }}></p>- {d.agente} - {new Date(d.inserimento).toLocaleString()}
                            </div>
                        )
                    })
                }
            </div>
        </React.Fragment>
    )
}

export default Immobile