import jwt from "jwt-decode";
import { proxy } from "../config";

export async function loginUser(obj) {
    //console.log(obj)
    const response = await fetch(`${proxy}/api/login`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(obj)
    });
    return response.json();
}

export async function getIp() {
  const response = await fetch("https://geolocation-db.com/json/");
  return response.json();
}

export async function jwtDec(token) {
  const response = await jwt(token)
  return response;
}