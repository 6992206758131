import { proxy } from "../config";

export async function getData(id) {
    console.log(id)
    const response = await fetch(`${proxy}/api/immobile/${id}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: ""
    });
    return response.json();
}


export async function getIndirizzo() {
    const response = await fetch(`${proxy}/api/indirizzo`);
    return response.json();
}

export async function getFabbricato() {
    const response = await fetch(`${proxy}/api/fabbricato`);
    return response.json();
}

export async function getCondizioni() {
    const response = await fetch(`${proxy}/api/condizioni`);
    return response.json();
}

export async function getImmobili() {
    const response = await fetch(`${proxy}/api/immobil`);
    return response.json();
}

export async function getAccessori() {
    const response = await fetch(`${proxy}/api/accessori`);
    return response.json();
}

export async function getRiscaldamento() {
    const response = await fetch(`${proxy}/api/riscaldamento`);
    return response.json();
}

export async function getClassi(certificazione) {
    const response = await fetch(`${proxy}/api/classi/${certificazione}`);
    return response.json();
}

export async function getImmobile(id) {
    const response = await fetch(`${proxy}/api/immobili/${id}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: ""
    });
    return response.json();
}

export async function addNewProperties(obj) {
    const response = await fetch(`${proxy}/api/immobile/add`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
    });
    return response.json();
}

export async function getProprietario(id) {
    const response = await fetch(`${proxy}/api/utenti/${id}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: ""
    });
    return response.json();
}

export async function updateImmobile(obj) {
    const response = await fetch(`${proxy}/api/immobile/${obj.idImmobile}/update`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
    });
    return response.json();
}

export async function uploadDoc(id, obj) {
    const response = await fetch(`${proxy}/api/immobile/${id}/document/upload`, {
        method: 'POST',
        body: obj
    });
    return response.json();
}

export async function getDocuments(id) {
    const response = await fetch(`${proxy}/api/documenti/${id}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: ""
    });
    return response.json();
}

export async function uploadImg(id,obj) {
    const response = await fetch(`${proxy}/api/immobile/${id}/foto/upload`, {
        method: 'POST',
        body: obj
    });
    return response.json();
}

export async function getImg(id,foto) {
    const response = await fetch(`${proxy}/api/immobile/${id}/foto`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({foto: foto})
    });
    return response;
}

export async function getImgSrc(obj) {
    const response = await fetch(`${proxy}/api/immagini/get`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
    });
    const blob = await response.blob();
    //const url = URL.createObjectURL(blob);
    return blob;
}

export async function swapImgIndex(id,obj) {
    const response = await fetch(`${proxy}/api/immobile/${id}/foto/swap`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
    });
    return response.json();
}

export async function deleteImg(id,obj) {
    const response = await fetch(`${proxy}/api/immobile/${id}/foto/delete`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
    });
    return response.json();
}

export async function downloadDoc(id,obj) {
    const response = await fetch(`${proxy}/api/immobile/${id}/document/download`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
    });
    const blob = await response.blob();
    //const url = URL.createObjectURL(blob);
    return blob;
}

export async function deleteDoc(id, obj) {
    const response = await fetch(`${proxy}/api/immobile/${id}/document/delete`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
    });
    return response.json();
}

export async function getDiario(id) {
    const response = await fetch(`${proxy}/api/diario/1/${id}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: ""
    });
    return response.json();
}