import React, { useState } from "react";
import CardUtenti from "../components/cards/CardUtenti";
//import UserCard from "../components/cards/UserCard";
import { searchUsers } from "../data/search";
//import { getDBUsers } from "../data/utenti";


const Utenti = () => {
    const [utenti, setUtenti] = useState([]);
    
    const search = (e) => {
        const obj = {
            nome: document.getElementById("nome").value,
            email: document.getElementById("email").value,
            numero: document.getElementById("numero").value
        }
        if (!(obj.nome === "" && obj.email === "" && obj.numero === "")) {
            searchUsers(obj).then(result => setUtenti(result.utenti));
        } else console.log("vuoto")
    }
    //console.log(utenti)


    return(
        <React.Fragment>
            <div className="row mt-4">
                <h3>Cerca Utenti</h3>
                <div className="col-sm-12 col-md-3">
                    <div className="input-group mb-3">
                        <input id="nome" type="text" className="form-control" placeholder="Nome" aria-label="Nome" aria-describedby="basic-addon1" onChange={search}/>
                    </div>
                </div>
                <div className="col-sm-12 col-md-3">
                    <div className="input-group mb-3">
                        <input id="email" type="text" className="form-control" placeholder="Email" aria-label="Email" aria-describedby="basic-addon1" onChange={search}/>
                    </div>
                </div>
                <div className="col-sm-12 col-md-3">
                    <div className="input-group mb-3">
                        <input id="numero" type="number" className="form-control" placeholder="Numero" aria-label="Numero" aria-describedby="basic-addon1" onChange={search}/>
                    </div>
                </div>
                <div className="col-sm-12 col-md-3">
                    <div className="input-group mb-3">
                    <button type="text" className="btn btn-primary w-100" onClick={() => window.location.href = "/utenti/add" }>Aggiungi</button>
                    </div>
                </div>
            </div>
            <hr />
            <div className="container mt-4">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3">
                    {
                        utenti && utenti.map((u, i) => {
                            return(
                                <div className="col" key={i}>
                                    <CardUtenti utente={u} />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default Utenti