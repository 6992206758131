import { proxy } from "../config";

const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

export async function searchProperties(obj) {
  const response = await fetch(`${proxy}/api/immobili/search`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(obj)
  });
  return response.json();
}

export async function searchUsers(obj) {
  const response = await fetch(`${proxy}/api/utenti/search`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(obj)
  });
  return response.json();
}