import React, { useEffect, useState } from "react"
import AgentCard from "../components/cards/AgentCard"
import { getAgente, updateAgente } from "../data/agente";
import { useParams } from "react-router-dom";

const Agente = () => {
    const { id } = useParams();
    const [agente, setAgente] = useState(null);
    const [isLoading, setLoading] = useState(true);
    
    useEffect(()=> {
        getAgente(id).then((result)=> setAgente(result.agente))
        setLoading(false);
    },[])

    const edit = (e) => {
        const element = e.target;
        if (element.type === "checkbox") {
            agente[element.name] = element.checked;
        } else agente[element.name] = element.value;
    }
    
    const submit = async (e) => {
        e.preventDefault();
        //console.log(agente)
        const update = await updateAgente(agente);
        //console.log(update)
        if (!update.ok) {
            const element = document.getElementsByName(update.error.field)[0]
            console.log(element)
            element.className +=" is-invalid"
            const root = element.parentElement
            const div = document.createElement("div");
            div.className = "invalid-feedback";
            div.innerHTML  = update.error.message;
            root.appendChild(div);
        }
    }
    
    return(
        <>
            <div className="row mt-5">
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <AgentCard agente={agente}/>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-8 mt-3">
                    <form className="border p-4" onSubmit={submit}>
                    {
                        !isLoading && agente ? 
                        <div>
                            <div className="input-group mb-3">
                                <input type="text" name="nome" defaultValue={agente.nome} className="form-control" placeholder="Nome" aria-label="Nome" aria-describedby="basic-addon1" onChange={edit}/>
                                <input type="text" value={agente.codice} className="form-control" placeholder="Codice" aria-label="Codice" aria-describedby="basic-addon1" disabled/>
                            </div>
                        
                        
                            <div className="input-group mb-3">
                                <input type="text" name="telefono" defaultValue={agente.telefono} className="form-control" placeholder="Telefono" aria-label="Telefono" aria-describedby="basic-addon1" onChange={edit}/>
                                <input type="text" name="telefono2" defaultValue={agente.telefono2} className="form-control" placeholder="Telefono 2" aria-label="Telefono 2" aria-describedby="basic-addon1" onChange={edit}/>
                            </div>
                        
                        
                            <div className="input-group mb-3">
                                <input type="email" name="email" defaultValue={agente.email} className="form-control" placeholder="email" aria-label="email" aria-describedby="basic-addon1" onChange={edit}/>
                                <input type="email" name="pec" defaultValue={agente.pec} className="form-control" placeholder="pec" aria-label="pec" aria-describedby="basic-addon1" onChange={edit}/>
                                
                            </div>

                            <div className="input-group mb-3">
                                <input type="text" name="piva" defaultValue={agente.piva} className="form-control" placeholder="piva" aria-label="piva" aria-describedby="basic-addon1" onChange={edit}/>
                                <input type="text" name="rea" defaultValue={agente.rea} className="form-control" placeholder="rea" aria-label="rea" aria-describedby="basic-addon1" onChange={edit}/>
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Iscrizione</label>

                                <input type="date" name="iscrizione" className="form-control" defaultValue={agente.iscrizione ? new Date(agente.iscrizione).toISOString().split("T")[0] : null} onChange={edit}/>

                            </div>
                            <div className="mb-3 form-check">
                                <input type="checkbox" name="visibile" className="form-check-input" id="exampleCheck1" defaultChecked={agente.visibile} onChange={edit}/>
                                <label className="form-check-label">Visibile</label>
                            </div>
                            <div className="mb-2">
                                <button type="submit" className="btn btn-primary w-100">Aggiorna</button>
                            </div>
                        </div>
                        : null
                    }
                    </form>
                </div>
            </div>
            
        </>
    )
}

export default Agente