import { proxy } from "../config";

export async function getPropertiesCard(immobile) {
    const response = await fetch(`${proxy}/api/cards/properties`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(immobile)
    });
    return response.json();
}

export async function getPreviewPropertiesCard(id) {
  const response = await fetch(`${proxy}/api/utente/proposte/${id}/foto`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: ""
  });
  
  const blob = await response.blob();
  if (!blob.type.includes("image")) return null;
  return blob;
}