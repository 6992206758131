import { proxy } from "../config";

export async function getAgente(codice) {
    const response = await fetch(`${proxy}/api/agente/${codice}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: ""
    });
    return response.json();
}

export async function getAgenti() {
  const response = await fetch(`${proxy}/api/agenti`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: ""
  });
  return response.json();
}

export async function updateAgente(obj) {
    const response = await fetch(`${proxy}/api/agente/update`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
    });
    return response.json();
}

export async function getIp() {
  const response = await fetch("https://geolocation-db.com/json/");
  return response.json();
}